import { createUltimatePagination } from "react-ultimate-pagination";

export default function UltimePager({ currentPage, total, onPageChange }) {
  const Button = ({ value, isActive, disabled, onClick }) => (
    <button
      style={
        isActive
          ? {
              background: "#eab308",
              color: "#fff",
            }
          : null
      }
      onClick={onClick}
      disabled={disabled}
    >
      {value}
    </button>
  );

  const PaginatedPage = createUltimatePagination({
    itemTypeToComponent: {
      PAGE: Button,
      ELLIPSIS: () => <Button value="...." />,
      FIRST_PAGE_LINK: () => <Button value="First" />,
      PREVIOUS_PAGE_LINK: () => (
        <Button
          value={
            <a href="#" style={{ color: "#697a8d" }}>
              <svg
                width={14}
                height={8}
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.1665 4H12.8332"
                  stroke="currentColor"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.1665 4L4.49984 7.33333"
                  stroke="currentColor"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.1665 4.00002L4.49984 0.666687"
                  stroke="currentColor"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
          }
        />
      ),
      NEXT_PAGE_LINK: () => (
        <Button
          value={
            <a href="#" style={{ color: "#697a8d" }}>
              <svg
                width={14}
                height={8}
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.1665 4H12.8332"
                  stroke="currentColor"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.5 7.33333L12.8333 4"
                  stroke="currentColor"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.5 0.666687L12.8333 4.00002"
                  stroke="currentColor"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
          }
        />
      ),
      LAST_PAGE_LINK: () => <Button value="Last" />,
    },
  });

  return (
    <div className="mt-3">
      <ul className="pagination_ultimate">
        <PaginatedPage
          currentPage={currentPage}
          totalPages={total}
          onChange={onPageChange}
        />
      </ul>
    </div>
  );
}
