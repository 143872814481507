import React, { createContext, useState, useContext } from "react";

const SearchContext = createContext();

export const useSearch = () => {
  return useContext(SearchContext);
};

const SearchProvider = ({ children }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const searchProducts = async (type, keyword) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://apiplanheure-jhonnjordans-projects.vercel.app/api/v1/products/search?type=${type}&keyword=${keyword}`
      );
      const data = await response.json();
      if (data.status === "error") {
        throw new Error(data.message);
      }
      setSearchResults(data.response);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


//   useEffect(() => {
//     setLoading(true)
//     const getProductDetail = async () => {       
//       productID && productID.length > 0 && await axios.get(`https://apiplanheure-jhonnjordans-projects.vercel.app/api/v1/product/${productID}`).then(
//         (res) => {
//           console.log(res.data.data)
//           setProduct(res.data.data)
//           setLoading(false)
//         }
//       );        
//     }
//     getProductDetail()
//   }, [productID])


  const values = { searchResults, searchProducts, loading, error };

  return (
    <SearchContext.Provider value={values}>{children}</SearchContext.Provider>
  );
};

export default SearchProvider;
