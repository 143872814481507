import React, { useState } from 'react';
import { useSearch } from '../../Context/SearchContext';

const SearchResults = () => {
  const { searchResults, searchProducts, loading, error } = useSearch();
  const [type, setType] = useState('');
  const [keyword, setKeyword] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    if (type && keyword) {
      searchProducts(type, keyword);
    }
  };

  return (
    <div>
      <form onSubmit={handleSearch}>
        <div>
          <label>Type:</label>
          <select value={type} onChange={(e) => setType(e.target.value)}>
            <option value="">Select Type</option>
            <option value="name">Name</option>
            <option value="price">Price</option>
            <option value="category">Category</option>
            <option value="description">Description</option>
          </select>
        </div>
        <div>
          <label>Keyword:</label>
          <input
            type="text"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
        </div>
        <button type="submit">Search</button>
      </form>

      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {!loading && searchResults.length === 0 && <p>No results found</p>}
      {!loading && searchResults.length > 0 && (
        <ul>
          {searchResults.map((product) => (
            <li key={product._id}>
              <h3>{product.name}</h3>
              <p>Price: {product.price}</p>
              <p>Category: {product.category}</p>
              <p>Description: {product.description}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchResults;
