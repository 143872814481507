import axios from 'axios'
import { createContext, useContext, useEffect, useState } from 'react'
const ProductContext = createContext()


export const ProductProvider = ({ children }) => {
  const [productList, setProductList] = useState([])
  const [categories, setCategories] = useState()
  const [category, setCategory] = useState("/products")
  const [productID, setProductID] = useState("")
  const [product, setProduct] = useState({})
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState('')
  const [type, setType] = useState('')
  const [keyword, setKeyword] = useState('')
  const [loading, setLoading] = useState(false)

  // useEffect(() => {
  //   setLoading(true)
  //   const getCategories = async () => {
      
  //     let categoriesData
  //     await axios("https://fakestoreapi.com/products/categories").then(
  //       (res) =>
  //         (categoriesData = res.data.map((item) =>
  //           item.replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase())
  //         ))
  //     )
  //     setCategories(categoriesData)
  //   }
  //   getCategories()
  //   setLoading(false)
  // }, [])
  
  useEffect(() => {
    setLoading(true)
    const getProductData = async () => {
      type!=='' &&keyword!=='' && await axios.get(`https://apiplanheure-jhonnjordans-projects.vercel.app/api/v1/products/search?type=${type}&keyword=${keyword}`).then((res) => {
        setProductList(res.data.response.reverse())
        console.log(res.data.response)
        setTotalPages(res.data.totalPages)
        setCategory("")
        setLoading(false)
      })
    }
    getProductData()
  }, [type,keyword])

  useEffect(() => {
    setLoading(true)
    const getProductData = async () => {
      await axios.get(`https://apiplanheure-jhonnjordans-projects.vercel.app/api/v1/products?page=${page}&limit=${8}`).then((res) => {
        setProductList(res.data.response.reverse())
        setTotalPages(res.data.totalPages)
        setCategory("")
        setLoading(false)
      })
    }
    getProductData()
  }, [page])

  useEffect(() => {
    setLoading(true)
    const getProductDetail = async () => {       
      productID && productID.length > 0 && await axios.get(`https://apiplanheure-jhonnjordans-projects.vercel.app/api/v1/product/${productID}`).then(
        (res) => {
          setProduct(res.data.data)
          setLoading(false)
        }
      );        
    }
    getProductDetail()
  }, [productID])


  const values = {
    product,
    productList,
    productID,
    setProductID,
    categories,
    setCategory,
    loading,
    setPage,
    totalPages,
    page,
    setType,
    setKeyword
  }

  return (
    <ProductContext.Provider value={values}>{children}</ProductContext.Provider>
  )
}

export const useProduct = () => useContext(ProductContext)