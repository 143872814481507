// const Spinner = () => {
//   return (
//     <>
//       <div
//         style={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//           height: "100vh",
//           width: "100vw",
//           background: "white",
//           objectFit: "cover",
//         }}
//       >
//         <div class="mx-auto max-w-xs md:max-w-lg lg:max-w-2xl xl:max-w-4xl">
//           <img
//             width={"124"}
//             height={"124"}
//             src={process.env.PUBLIC_URL + `/images/logo.jpeg`}
//             alt={process.env.PUBLIC_URL + `/images/logo.jpeg`}
//             class="mx-auto block"
//           />
//         </div>
//       </div>
//     </>
//   );
// };

// export default Spinner;



import React from 'react';

const Spinner = () => {
  return (
    <div className="flex items-center justify-center h-screen w-100 bg-white">
      <div className="flex justify-center">
        <img
          width="124"
          height="124"
          src={`${process.env.PUBLIC_URL}/images/logo.jpeg`}
          alt="Loading..."
          className="block"
        />
      </div>
    </div>
  );
};

export default Spinner;
