import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer className="bg-gray-800 py-6">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4">
            <div className="text-white">
              <h3 className="mb-2 text-lg font-semibold">À propos</h3>
              <p>Produit et service · Immobilier</p>
            </div>
            <div className="text-white">
              <h3 className="mb-2 text-lg font-semibold">Liens rapides</h3>
              <ul>
                <li>
                  <Link to="/" className="text-gray-300 hover:text-white">
                    Accueil
                  </Link>
                </li>
                {/* <li>
                  <a href="#" className="text-gray-300 hover:text-white">
                    Produits
                  </a>
                </li>
                <li>
                  <a href="#" className="text-gray-300 hover:text-white">
                    Services
                  </a>
                </li> 
                <li>
                  <a href="#" className="text-gray-300 hover:text-white">
                    Contact
                  </a>
                </li> */}
              </ul>
            </div>
            <div className="text-white">
              <h3 className="mb-2 text-lg font-semibold">Suivez-nous</h3>
              <ul>
                <li>
                  <a href="https://www.facebook.com/plandereve" className="text-gray-300 hover:text-white flex items-center">
                  <Icon icon="ic:twotone-facebook" fontSize="1.62rem"/>    <span className="ml-2">Facebook</span>
                  </a>
                </li>
                {/* <li>
                  <a href="#" className="text-gray-300 hover:text-white">
                    Twitter
                  </a>
                </li>
                <li>
                  <a href="#" className="text-gray-300 hover:text-white">
                    Instagram
                  </a>
                </li>
                <li>
                  <a href="#" className="text-gray-300 hover:text-white">
                    LinkedIn
                  </a>
                </li> */}
              </ul>
            </div>
            {false&& <div className="text-white">
              <h3 className="mb-2 text-lg font-semibold">Contact</h3>

              <a href="tel:00241077640451" className="flex items-center ">
                <Icon icon="la:phone" />
                <span className="mx-[6px]">241077640451</span>
               {false&& <p className="mt-4 text-base leading-4 text-white">
                  contact +241 74016386 / 62000325
                </p>}
              </a>
            </div>}
          </div>
        </div>
      </footer>
    </>
  );
}
