import React from 'react'
import './App.css'
import { Routes, Route } from 'react-router-dom'
// import Signin from './Pages/Auth/Signin'
// import Signup from './Pages/Auth/Signup'
// import Favorites from './Pages/Favorites'
import Products from './Pages/Products'
import Error404 from './Pages/Error404'
import Container from './Components/Container'
import ProductDetail from './Pages/ProductDetail'
import Cart from './Pages/Cart'
import NavNav from './Components/Navbar/NavNav'
import LittleHero from './Components/Hiro/LittleHero'
import Comment from './Components/Comment/Comment'
import { useProduct } from './Context/ProductContext'
import HiddenHiro from './Components/Hidden/HiddenHiro'
import HiddenComment from './Components/Hidden/HiddenComment'
import HiddenFooter from './Components/Hidden/HiddenFooter'
import Footer from './Components/Footer'
import SearchResults from './Components/Search'

function App() {
  const showLittleHero = HiddenHiro()
  const showComment = HiddenComment()
  const showFooter = HiddenFooter()

  const { loading } = useProduct()
  
  return (
    <div className={!loading&& `${"theme mx-auto"}`}>      
      {!loading&& <NavNav />}

      {!loading&& showLittleHero&& <LittleHero />}

      <Container>
        <Routes>
          <Route path="/" exact element={<Products />} />
         
          <Route path="/detail/:name/:category/:product_id" element={<ProductDetail  />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/search" element={<SearchResults />} />

          {/* <Route path="/:category_id" element={<Products />} /> */}
          {/* <Route path="/favorites" element={<Favorites />} /> */}
          {/* <Route path="/signin" element={<Signin />} /> */}
          {/* <Route path="/signup" element={<Signup />} /> */}
          
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Container>

      {false&& !loading&& showComment&& <Comment />}
      {!loading&& showFooter&& <Footer />}
    </div>
  )
}

export default App