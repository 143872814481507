import { useLocation } from "react-router-dom";

function HiddenHiro() {
  const location = useLocation();
  const path = location.pathname;

  const hiddenPaths = ["/detail", "/cart"];

  return !hiddenPaths.some((hiddenPath) => path.startsWith(hiddenPath));
}

export default HiddenHiro;
