import React, {useEffect} from "react";
import { useProduct } from "../../Context/ProductContext";
import styles from "./styles.module.css";
import Spinner from "../../Components/Spinner";
import { useParams } from "react-router-dom";
import { useCart } from '../../Context/CartContext'
import { useFavorite } from '../../Context/FavoriteContext'
import Card from "../../Components/Card";
import UltimePager from "../../Components/Paginate";

const Products = () => {
  const {addToCart, items} = useCart()
  const {addToFavorite, favoriteItems} = useFavorite()

  const { page, setPage, totalPages , productList, loading, setProductID, setCategory } = useProduct();

  const {category_id} = useParams()

  const handlePageChange = (page) => {
    setPage(page)
  }

  useEffect(() => {
    setCategory(category_id)
  }, [category_id])

  return ( 
    <>   
      {!loading ? (
        <div  className={`${loading ? "hidden" : "my-10 md:px-[8rem]" }`} >
        {/* <div className={`${loading ? "" : " grid grid-cols-1 gap-4 p-3 sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-4 " }`}> */}
        <div className={`${loading ? "hidden" : "grid grid-cols-1 gap-4 p-3 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4" }`}>
          {!loading ? (
            productList?.map((item, index) => {
              const findCartItem = items.find((cart_item) => cart_item._id === item._id) 
              const findFavoriteItem = favoriteItems.find((favorite_item) => favorite_item._id === item._id)
              return (
                <>
                  <div key={item._id}>
                    <Card key={`${item._id}`} item={item} setProductID={setProductID} findCartItem={findCartItem} findFavoriteItem={findFavoriteItem} addToCart={addToCart} addToFavorite={addToFavorite} />
                  </div>  
                </>  
              );
            })
          ) : (
            null
          )}
        </div>     
        {!loading&& totalPages > 1 ? (
          <div className="ml-3 pt-4"><UltimePager
            total={totalPages}
            currentPage={page}
            onPageChange={handlePageChange}
          /></div>
        ) : null}
      </div>
      ):
      <Spinner />
      }
    </>
  );
};

export default Products;
