import React from "react";
import { Icon } from "@iconify/react";
import { useProduct } from "../../Context/ProductContext";

const LittleHero = () => {
  const { loading, setType, setKeyword } = useProduct();

  return (
    <div className="md:px-[8rem]">
      <div
        className="no-repeat relative h-[300px] rounded-[9px] bg-center"
        style={{
          backgroundImage:
            'url("https://media.istockphoto.com/id/1658734737/fr/vid%C3%A9o/central-park-new-york-city-aerial-day-time-video-4k.jpg?s=640x640&k=20&c=huL52Kb6AtktsgIXvwEE98HIMBVWwSqnRgbrygcUD9k=")',
        }}
      >
        <div className="bg-black absolute inset-0 opacity-50"></div>
       {false&& <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-full max-w-md px-4">
            <form className="rounded-lg bg-white p-4 shadow-md">
              <div>
                <label>Type:</label>
                <select value={'type'} onChange={(e) => setType(e.target.value)}>
                  <option value="">Select Type</option>
                  <option value="name">Name</option>
                  <option value="price">Price</option>
                  <option value="category">Category</option>
                  <option value="description">Description</option>
                </select>
              </div>
              <div className="flex items-center border-b border-b-2 border-gray-300 py-2">
                <input
                  className="bg-transparent mr-3 w-full appearance-none border-none py-1 px-2 leading-tight text-gray-700 focus:outline-none"
                  type="text"
                  placeholder="Rechercher un plan..."
                  aria-label="Full name"
                  onChange={(e) => setKeyword(e.target.value)}
                />
                <a href="#" className="flex items-center text-[#000]">
                  <Icon icon="icon-park-outline:search" fontSize={"1.5rem"} />
                </a>
              </div>
            </form>
          </div>
        </div>}
      </div>
    </div>
  );
};

export default LittleHero;
