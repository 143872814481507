import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import { Icon } from "@iconify/react"
import "../../Libs/CustumStyle.css"

import { ShoppingCartIcon, HeartIcon, StarIcon } from "@heroicons/react/solid"
import Spinner from "../../Components/Spinner"
import { useProduct } from "../../Context/ProductContext"
import { useCart } from "../../Context/CartContext"
import { useFavorite } from "../../Context/FavoriteContext"
import styles from "./styles.module.css"
import InnerTag from "../../Components/Tag/InnerTag"

const ProductDetail = () => {
  const { addToCart, items } = useCart()
  const { addToFavorite, favoriteItems } = useFavorite()
  const { product, loading, setProductID } = useProduct()
  const [itemsDescription, setItemsDescription] = useState([])

  const findCartItem = items.find((item) => item._id === product._id)
  const findFavoriteItem = favoriteItems.find(
    (item) => item._id === product._id
  )

  const { product_id } = useParams() 

  const images = [
    product?.image,
    ...(product?.thumbnails
      ? product.thumbnails.map((thumbnail) => thumbnail.image)
      : []),
  ]


  const [selectedImage, setSelectedImage] = useState("")

  const handleImageClick = (image) => {
    setSelectedImage(image)
  }

  useEffect(() => {
    setProductID(product_id)
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Pour une animation fluide
    })
  }

  const parseDescription = (desc) => {
    if (typeof desc !== "string") {
      return [] // Retourne un tableau vide si desc n'est pas une chaîne
    }
    return desc.split(" //+").map((item) => item.trim())
  }

  useEffect(() => {
    if (product && product.description) {
      setItemsDescription(parseDescription(product.description))
    }
  }, [product])

  
  const formatPrice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
  }

  // const parseDescription = (desc) => {
  //   const rawItems = desc.split(" //+").map((item) => item.trim())
  //   return rawItems
  // }
  // // setItemsDescription(parseDescription(product.description))

  // console.log(parseDescription(product.description))

  // useEffect(() => {

  // }, [product.description])

  return (
    <>
      {!loading && product?._id ? (
        <>
          <br />
          <div className="align-item-center mx-auto flex max-w-7xl px-4 sm:px-6 lg:px-8">
            <Link
              to="/"
              className="border-[none] align-item-center block flex items-center rounded-md  text-base font-medium text-gray-300 hover:text-white"
            >
              <Icon icon="tabler:home-down" />
              <span className="mx-[6px]">Accueil</span>
            </Link>
            <span className="border-[none] align-item-center block flex items-center rounded-md  text-base font-medium text-gray-300 hover:text-white">
              <Icon icon="ph:caret-right-bold" className="text-white " />
            </span>
            <span className="border-[none] align-item-center block flex items-center rounded-md text-base font-medium text-white  hover:text-white">
              <Icon icon="majesticons:checkbox-list-detail-line" />
              <span className="mx-[6px] text-[#2e7601] font-semibold">Detail</span>
            </span>
          </div>

          <>
            <div className="items-start justify-center py-12 px-4 md:flex md:px-6 2xl:px-20">
              <div className="itemsView w-80 sm:hidden md:block  lg:block lg:w-2/5 xl:w-2/6">
                <img
                  key={product_id}
                  className="h-[375px] w-full object-cover object-center hro"
                  alt="img of a girl posing"
                  src={
                    selectedImage === "" ? product.image : `${selectedImage}`
                  }
                />

                <div className="mt-4 flex gap-4">
                  {images.map((item, index) => (
                    <>
                      <img
                        key={index}
                        className={`h-20 w-20 cursor-pointer rounded-sm object-cover ${
                          selectedImage === item &&
                          "border-2 border-blue-500"
                        }`}
                        src={`${item}`}
                        alt={`Product Thumbnail ${index + 1}`}
                        onClick={() => handleImageClick(item)}
                      />
                    </>
                  ))}
                </div>


                {/* <div className="mt-4 flex gap-4">
                  {product.thumbnails.map((item, index) => (
                    <>
                      <img
                        key={index}
                        className={`h-20 w-20 cursor-pointer rounded-sm object-cover ${
                          selectedImage === item.image &&
                          "border-2 border-blue-500"
                        }`}
                        src={`${item.image}`}
                        alt={`Product Thumbnail ${index + 1}`}
                        onClick={() => handleImageClick(item.image)}
                      />
                    </>
                  ))}
                </div> */}

                {/* {product.thumbnails.length > 0 && (
                  <CarouselProvider
                    className=" block md:block lg:block "
                    naturalSlideWidth={100}
                    isIntrinsicHeight={true}
                    totalSlides={product.thumbnails.length}
                    visibleSlides={1}
                    step={1}
                    infinite={true}
                  >
                    <div className="relative flex h-[29em] w-full items-center justify-center">
                      <ButtonBack
                        role="button"
                        aria-label="slide backward"
                        className="absolute left-0 z-30 ml-8 cursor-pointer focus:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
                        id="prev"
                      >
                        <svg
                          width={18}
                          height={18}
                          viewBox="0 0 8 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7 1L1 7L7 13"
                            stroke="white"
                            strokeWidth={3}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </ButtonBack>
                      <div className="mx-auto h-full w-full overflow-x-hidden overflow-y-hidden">
                        <Slider>
                          {product.thumbnails.map((item, index) => (
                            <>
                              <div
                                key={index}
                                id="slider"
                                className="mt-3 flex h-full w-full items-center justify-start transition duration-700 ease-out"
                              >
                                <Slide index={index}>
                                  <div className="relative flex w-full flex-shrink-0 sm:w-auto">
                                    <img
                                      src={`${item.image}`}
                                      alt="black chair and white table "
                                      className="h-[375px] w-full object-cover object-center"
                                    />
                                    <div className="absolute h-full w-full bg-gray-800 bg-opacity-30 p-6">
                                      <h2 className="text-base uppercase leading-4 text-white lg:text-xl lg:leading-5">
                                        image {index + 1}
                                      </h2>
                                    </div>
                                  </div>
                                </Slide>
                              </div>
                            </>
                          ))}
                        </Slider>
                      </div>
                      <ButtonNext
                        role="button"
                        aria-label="slide forward"
                        className="absolute right-0 z-30 mr-8 focus:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
                        id="next"
                      >
                        <svg
                          width={18}
                          height={18}
                          viewBox="0 0 8 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1L7 7L1 13"
                            stroke="white"
                            strokeWidth={3}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </ButtonNext>
                    </div>
                  </CarouselProvider>
                )} */}
                

                {product.thumbnails.length > 0 && (
                  <CarouselProvider
                    className=" block md:block lg:block "
                    naturalSlideWidth={100}
                    isIntrinsicHeight={true}
                    totalSlides={images.length}
                    visibleSlides={1}
                    step={1}
                    infinite={true}
                  >
                    <div className="relative flex h-[29em] w-full items-center justify-center">
                      <ButtonBack
                        role="button"
                        aria-label="slide backward"
                        className="absolute left-0 z-30 ml-8 cursor-pointer focus:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
                        id="prev"
                      >
                        <svg
                          width={18}
                          height={18}
                          viewBox="0 0 8 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7 1L1 7L7 13"
                            stroke="white"
                            strokeWidth={3}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </ButtonBack>
                      <div className="mx-auto h-full w-full overflow-x-hidden overflow-y-hidden">
                        <Slider>
                          {images.map((item, index) => (
                            <>
                              <div
                                key={index}
                                id="slider"
                                className="mt-3 flex h-full w-full items-center justify-start transition duration-700 ease-out"
                              >
                                <Slide index={index}>
                                  <div className="relative flex w-full flex-shrink-0 sm:w-auto">
                                    <img
                                      src={`${item}`}
                                      alt="product "
                                      className="h-[375px] w-full object-cover object-center"
                                    />
                                    <div className="absolute h-full w-full bg-gray-800 bg-opacity-30 p-6">
                                      <h2 className="text-base uppercase leading-4 text-white lg:text-xl lg:leading-5">
                                        image {index + 1}
                                      </h2>
                                    </div>
                                  </div>
                                </Slide>
                              </div>
                            </>
                          ))}
                        </Slider>
                      </div>
                      <ButtonNext
                        role="button"
                        aria-label="slide forward"
                        className="absolute right-0 z-30 mr-8 focus:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
                        id="next"
                      >
                        <svg
                          width={18}
                          height={18}
                          viewBox="0 0 8 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1L7 7L1 13"
                            stroke="white"
                            strokeWidth={3}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </ButtonNext>
                    </div>
                  </CarouselProvider>
                )}

              </div>
              <div className="block md:hidden">
                <CarouselProvider
                  className="block md:hidden "
                  naturalSlideWidth={100}
                  isIntrinsicHeight={true}
                  totalSlides={images.length}
                  visibleSlides={1}
                  step={1}
                  infinite={true}
                >
                  <div className="relative flex w-full items-center justify-center">
                    <ButtonBack
                      role="button"
                      aria-label="slide backward"
                      className="absolute left-0 z-30 ml-8 cursor-pointer focus:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
                      id="prev"
                    >
                      <svg
                        width={18}
                        height={18}
                        viewBox="0 0 8 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7 1L1 7L7 13"
                          stroke="white"
                          strokeWidth={3}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </ButtonBack>
                    <div className="mx-auto h-full w-full overflow-x-hidden overflow-y-hidden">
                      <Slider>
                        {images.map((item, index) => (
                          <>
                            <div
                              key={index}
                              id="slider"
                              className="flex h-full w-full items-center justify-start transition duration-700 ease-out md:gap-6 lg:gap-8"
                            >
                              <Slide index={index}>
                                <div className="relative flex w-full flex-shrink-0 sm:w-auto">
                                  <img
                                    src={item}
                                    alt="product"
                                    className="h-[330px] w-full object-cover object-center"
                                  />
                                  <div className="absolute h-full w-full bg-gray-800 bg-opacity-30 p-6">
                                    <h2 className="text-base uppercase leading-4 text-white lg:text-xl lg:leading-5">
                                      image {index + 1}
                                    </h2>
                                  </div>
                                </div>
                              </Slide>
                            </div>
                          </>
                        ))}
                      </Slider>
                    </div>
                    <ButtonNext
                      role="button"
                      aria-label="slide forward"
                      className="absolute right-0 z-30 mr-8 focus:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
                      id="next"
                    >
                      <svg
                        width={18}
                        height={18}
                        viewBox="0 0 8 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1L7 7L1 13"
                          stroke="white"
                          strokeWidth={3}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </ButtonNext>
                  </div>
                </CarouselProvider>
              </div>
              <div className="mt-6 md:ml-6 md:mt-0 md:w-1/2 lg:ml-8 xl:w-2/5">
                <div className="border-b border-gray-200 pb-6">
                  {/* <p className="text-sm leading-none text-gray-600">Plan'Heure</p> */}
                  <h1
                    className="
                              mt-2
                              text-xl
                              font-semibold
                              leading-7
                              text-gray-800
                              lg:text-2xl
                              lg:leading-6
                          "
                  >
                    <span className="capitalize text-[#fff] opacity-90">
                      Informations du plan :
                    </span>
                    {/* <span className="text-[#eab308] opacity-90">
                      {" "}
                      2 Chambres
                    </span>
                    <span className="text-[#2e7601] opacity-90 uppercase">
                      {product.reference} 2 Chambres
                    </span> */}
                  </h1>
                </div>

                <div>
                  <span className="text-white">
                    <br />
                    {/* <InnerTag str={product.description} /> */}

                    <ul>
                      {itemsDescription.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </span>

                  <h4 className="mt-7 text-white text-[1.6rem]">
                    Prix : {`${formatPrice(product.price)} cfa`}
                  </h4>
                </div>

                <br />

                <h4 className="mt-7 text-[#eab308] text-[1.6rem] underline">
                  Livraison :
                </h4>

                <h4 className="mt-3 text-[#2e7601] text-base">
                  <span className="mt-4 text-base leading-4 text-white">
                    Plan avec dimensions vues 2D et 3D <br />
                    Estimation des matériaux.
                  </span>
                </h4>

                <br />

                <Link
                  onClick={scrollToTop}
                  className="text-black"
                  to="/cart"
                  // href={`https://wa.me/241074016386?text=https://planheure.net/images/plan/${product.imagePrincial}%2C+Bonjour%2C+je+suis+intéresser+par+le+plan+de+cette+maison+Merci`}
                  // target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <button
                    className="
              text-black mt-5 flex w-full
              items-center
              justify-center
              rounded-md
              bg-yellow-500
              py-4
              text-base
                          font-semibold
              uppercase
              leading-none
              hover:bg-yellow-400
                          focus:outline-none
                          focus:ring-2
                          focus:ring-yellow-500
                          focus:ring-offset-2
            "
                  >
                    paiement
                  </button>
                </Link>

                <button
                  onClick={() => addToCart(product, findCartItem)}
                  className={`${
                    findCartItem
                      ? "text-white mt-5 flex w-full items-center justify-center rounded-md  bg-red-700 py-4 text-base font-semibold uppercase leading-non  hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-700 focus:ring-offset-2"
                      : "text-white mt-5 flex w-full items-center justify-center rounded-md  bg-blue-700 py-4 text-base font-semibold uppercase leading-non  hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2"
                  }`}
                >
                  <ShoppingCartIcon
                  className="w-[1.5rem] h-[1.25rem]"
                      aria-hidden="true"
                    />

                  <div className="ml-2 flex flex-col self-center">
                    <span>
                      {findCartItem ? "Retirer du panier" : "Ajouter au Panier"}
                    </span>
                  </div>
                </button>

                {!true && (
                  <button>
                    <a
                      style={{ color: "white" }}
                      href={`https://wa.me/241074016386?text=https://planheure.net/images/plan/${product.imagePrincial}%2C+Bonjour%2C+je+suis+intéresser+par+le+plan+de+cette+maison+Merci`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Réserver
                    </a>
                  </button>
                )}
              </div>
            </div>

            {false && (
              <div className="block ml-auto my-auto mt-0">
                <div className={styles.addToCart}>
                  <button
                    className={styles.addToCartButton}
                    onClick={() => addToCart(product, findCartItem)}
                  >
                    <ShoppingCartIcon
                      className={styles.shoppingCartIcon}
                      aria-hidden="true"
                    />

                    <div className="flex flex-col self-center">
                      <span className={styles.buttonText}>
                        {findCartItem
                          ? "Retirer du panier"
                          : "Ajouter au Panier"}
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            )}
          </>
        </>
      ) : (
        <Spinner />
      )}
    </>
  )
}

export default ProductDetail
