import React from "react"
import { ShoppingCartIcon, TrashIcon } from "@heroicons/react/outline"
import { Link } from "react-router-dom"
import { useCart } from "../../Context/CartContext"
import styles from "./styles.module.css"
import { Icon } from "@iconify/react"

const Cart = () => {
  const { items, removeFromCart } = useCart()

  const subtotal = items.reduce((acc, obj) => acc + obj.price, 0).toFixed(1)
  const parseDescription = (desc) => {
    if (typeof desc !== "string") {
      return [] // Retourne un tableau vide si desc n'est pas une chaîne
    }
    return desc.split(" //+").map((item) => item.trim())
  }

  return (
    <div className="h-screen">
      <br />
      <div className="align-item-center mx-auto flex max-w-7xl px-4 sm:px-6 lg:px-8">
        <a
          href="/"
          className="border-[none] align-item-center block flex items-center rounded-md  text-base font-medium text-gray-300 hover:text-white"
        >
          <Icon icon="tabler:home-down" />
          <span className="mx-[6px]">Accueil</span>
        </a>
        <span className="border-[none] align-item-center block flex items-center rounded-md  text-base font-medium text-gray-300 hover:text-white">
          <Icon icon="ph:caret-right-bold" className="text-white " />
        </span>
        <span className="border-[none] align-item-center block flex items-center rounded-md text-base font-medium text-white  hover:text-white">
          <Icon icon="majesticons:checkbox-list-detail-line" />
          <span className="mx-[6px] text-[#2e7601] font-semibold">Panier</span>
        </span>
      </div>
      {items.length < 1 && (
        <div className="flex flex-wrap max-w-7xl mx-auto my-4">
          <div className="w-full sm:w-2/2 md:w-2/2 xl:w-5/5 p-4 h-[500px] my-auto">
            <div className={'bg-white py-6'}>
            <div className="mt-10"></div>
              <p className="text-xl font-extralight tracking-widest text-center pt-6">
                Panier vide
              </p>
              <Link to="/">
                <div className={styles.continueButton}>
                  <button className={'bg-[#2e7601] rounded-md px-5 py-2 text-white font-semibold'}>
                    <div className="flex flex-col self-center">
                      <span className={styles.buttonText}>
                        Continue 
                      </span>
                    </div>
                  </button>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}

      {items.length > 0 && (
        <div className="flex flex-wrap max-w-7xl mx-auto my-4">
          <div className="flex flex-col flex-1">
            {items.map((item) => {
              return (
                <div
                  className="w-full sm:w-2/2 md:w-2/2 xl:w-5/5 p-4 my-auto"
                  key={item._id}
                >
                  <div className={styles.bgCart}>
                    <div className="flex flex-row h-48">
                      <img
                        className="w-32 my-auto p-4 object-contain"
                        src={item.image}
                        alt="Cart Item"
                      />
                      <div className="flex flex-col ml-2 mt-2">
                        <Link
                          to={`/detail/${item.name
                            .split(" ")
                            .join("-")}/${item.category.split(" ").join("-")}/${
                            item._id
                          }`}
                        >
                          <h2 className="text-sm title-font text-zinc-900 tracking-widest hover:text-blue-600 mt-2">
                            {item.name}
                          </h2>
                          <p className="text-sm title-font text-zinc-900 tracking-widest hover:text-blue-600 mt-2">
                            {item.description}
                            {/* <ul>
                              {parseDescription(item.description).map((item, index) => (
                                <li key={index}>{item}</li>
                              ))}
                            </ul> */}
                          </p> <br/>
                          <span className="text-sm title-font text-zinc-900 tracking-widest hover:text-blue-600 mt-2">
                            {item.category}
                          </span>
                          <p className="font-extralight">{item.title}</p>
                        </Link>
                        <p className="mt-auto mb-4 font-extralight text-xl">
                          {item.price} cfa
                        </p>
                      </div>
                      <div className="flex flex-row ml-auto">
                        <button
                          className="w-5 h-5 ml-auto m-4 hover:text-red-500"
                          onClick={() => removeFromCart(item._id)}
                        >
                          <TrashIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="w-full h-full sm:w-2/2 md:w-2/2 xl:w-1/5 p-4">
            <div className={styles.bgCart}>
              <div className="flex flex-col p-4">
                <span className="text-xl mb-4 font-semibold">
                  Récapitulatif
                </span>
                <span className="text-sm my-2 font-extralight flex">
                  Total{" "}
                  <span className="ml-auto font-normal"> {subtotal} cfa</span>
                </span>
                <span className="text-sm my-2 font-extralight flex">
                  Frais <span className="ml-auto font-normal"> 1500 cfa</span>
                </span>
                <span className="text-sm my-2 font-extralight flex">
                  Tax <span className="ml-auto font-normal"> 1500 cfa</span>
                </span>
                <span className="text-md my-2 font-normal flex">
                  A Payer{" "}
                  <span className="ml-auto">
                    {" "}
                    {parseFloat(subtotal) + 3000} cfa
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Cart
