import { Icon } from "@iconify/react"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import CartButton from "./CartButton"
import "../../Libs/CustumStyle.css"
import { ShoppingCartIcon } from "@heroicons/react/solid"

const NavNav = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Pour une animation fluide
    })
  }

  return (
    <nav className="text-[] sticky top-0 z-50 bg-[#141526] py-2 text-[12px]">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
          <div className="flex items-center">
            <a href="/"   onClick={scrollToTop}>
              <div className="flex-shrink-0">
                <img
                  width={80}
                  height={80}
                  objectfit={"contain"}
                  src={process.env.PUBLIC_URL + `/images/logo.jpeg`}
                  alt={process.env.PUBLIC_URL + `/images/logo.jpeg`}
                  className="h-9 w-full rounded-full object-cover"
                />
              </div>
            </a>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                {/* <a
                  href="#"
                  className="flex items-center rounded-md px-3 py-2 text-[15px] font-medium text-[#fff] text-gray-300 hover:bg-gray-700 hover:text-white"
                >
                  {" "}
                  <Icon icon="mingcute:house-2-line" />
                  <span className="mx-[6px]">Accueil</span>
                </Link> */}
                <Link
                  to="/"
                  className="flex items-center rounded-md px-3 py-2 text-[15px] font-medium text-[#fff] text-gray-300 hover:bg-[#eab308] hover:text-white"
                >
                  {" "}
                  <Icon icon="mingcute:house-2-line" />
                  <span className="mx-[6px]">Duplex</span>
                </Link>
                <Link
                  to="/"
                  className="flex items-center rounded-md px-3 py-2 text-[15px] font-medium text-[#fff] text-gray-300 hover:bg-[#eab308] hover:text-white"
                >
                  {" "}
                  <Icon icon="mingcute:house-2-line" />
                  <span className="mx-[6px]">Plain pied</span>
                </Link>
                <Link
                  to="/"
                  className="flex items-center rounded-md px-3 py-2 text-[15px] font-medium text-[#fff] text-gray-300 hover:bg-[#eab308] hover:text-white"
                >
                  {" "}
                  <Icon icon="mingcute:house-2-line" />
                  <span className="mx-[6px]">Appartement</span>
                </Link>
                <Link
                  to="/cart"
                  className="flex items-center rounded-md px-3 py-2 text-[15px] font-medium bg-[#eab308] text-white relative"
                >
                  {/* <Icon icon="icon-park-outline:shopping" /> */}
                  <ShoppingCartIcon
                  className="w-[1.5rem] h-[1.25rem]"
                      aria-hidden="true"
                    />
                  <CartButton />
                </Link>
              </div>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={toggleMenu}
              type="button"
              className="hover:gray-800 inline-flex items-center justify-center rounded-md bg-gray-900 p-2 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                {isOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                )}
              </svg>
            </button>
          </div>
          {/* Boutons à droite */}
          <div className="hidden items-center md:flex">
            {/* hover:border-b hover:border-[#2e7601] */}
            {/* <button className="justify-content-between bg-[]  flex py-2 px-4  text-white ">
              <span className="mx-[8px]">
                <Icon icon="la:phone" />
              </span>
              <span className="text-[15px]">Me contacter</span>
            </button> */}

            <Link
              // to="tel:00241077640451"
              to="tel:0024177767191"
              className="flex items-center rounded-md px-3 py-2 text-[15px] font-medium text-[#fff] text-gray-300 hover:bg-[#2e7601] hover:text-white"
            >
              {" "}
              <Icon icon="la:phone" />
              <span className="mx-[6px]">Me contacter</span>
            </Link>
            {/* <button className="ml-4 rounded bg-blue-500 py-2 px-4  text-white hover:bg-blue-700">
              Bouton 2
            </button> */}
          </div>
        </div>
      </div>
      <div className={`${isOpen ? "block" : "hidden"} md:hidden`}>
        <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
          {/* <Link to="#"
            className="block block flex items-center rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
          >
            <Icon icon="mingcute:house-2-line" />

            <span className="mx-[6px]">Accueil</span>
          </Link> */}
          <Link
            to="#"
            className="block block flex items-center rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
          >
            <Icon icon="mingcute:house-2-line" />

            <span className="mx-[6px]">Duplex</span>
          </Link>
          <Link
            to="#"
            className="block block flex items-center rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
          >
            <Icon icon="mingcute:house-2-line" />
            <span className="mx-[6px]">Plain pied</span>
          </Link>
          <Link
            to="#"
            className="block flex items-center rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
          >
            <Icon icon="mingcute:house-2-line" />

            {/* <span class="text-blue-500 hover:underline"></span> */}
            <span className="mx-[6px]">Appartement</span>
          </Link>
          <Link
            to="tel:0024177767191"
            className="block flex items-center rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
          >
            <Icon icon="la:phone" fontSize={"16px"} />
            <span className="mx-[6px]">Me contacter</span>
          </Link>
          <Link
            to="/cart"
            className="block flex items-center rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
            style={{ position: "relative" }}
          >
            {/* <Icon icon="icon-park-outline:shopping" /> */}

              <ShoppingCartIcon
                  className="w-[1.5rem] h-[1.25rem]"
                  
                      aria-hidden="true"
                  />
            <CartButton />
          </Link>
        </div>
      </div>
    </nav>
  )
}

export default NavNav
