import React from "react";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";
import { ShoppingCartIcon } from "@heroicons/react/outline";
import { useCart } from "../../../Context/CartContext";

const CartButton = () => {
  const { items } = useCart();

  return (
    // <div className="flex flex-col self-center">
    //   {items.length > 0 && (
    //     <span className=
    //     "w-6 h-6 bg-red-500 rounded-full absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2"
    //     >{items.length}</span>
    //   )}
    // </div>
    <div className="flex flex-col items-center justify-center relative">
      {items.length > 0 && (
        <span className="flex items-center justify-center w-6 h-6 pt-1 text-sm bg-red-700 text-white rounded-full absolute inset-1/2 transform-translate-x-1/2-translate-y-1/2">
          {items.length}
        </span>
      )}
    </div>
  );
};

export default CartButton;
