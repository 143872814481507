// import styles from "./styles.module.css"
// import { StarIcon, ShoppingCartIcon, HeartIcon } from "@heroicons/react/solid"
// import { useState } from "react"
// import { Link } from "react-router-dom"
// import InnerTag from "../Tag/InnerTag"

// const Card = ({
//   item,
//   addToFavorite,
//   findFavoriteItem,
//   addToCart,
//   findCartItem,
// }) => {
//   const [selectedCategory, setSelectedCategory] = useState("all")

//   const handleCategoryClick = (category) => {
//     setSelectedCategory(category)
//   }

//   const scrollToTop = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: "smooth", // Pour une animation fluide
//     })
//   }

//   return (
//     <div className="w-100">
//       <div className="max-w-sm overflow-hidden rounded-[8px] shadow-lg">
//         <Link
//           // onMouseEnter={() => handleMouseEnter(item._id)}
//           to={`/detail/${item.name.split(" ").join("-")}/${item.category
//             .split(" ")
//             .join("-")}/${item._id}`}
//           onClick={scrollToTop}
//         >
//           <img
//             className="h-[219px] w-full object-cover object-center"
//             src={item.image}
//             alt={item.name}
//           />
//         </Link>
//         <div className="bg-[#fff] px-6 py-4" style={{ fontFamily: "Poppins" }}>
//           <span className="text-base uppercase">
//             <InnerTag
//               color={"#2e7601"}
//               truncate={true}
//               str={item.description}
//             />
//           </span>
//           <p className="mt-2 text-base text-lg font-bold text-[#0000CC]">
//             {`Prix : ${item.price} cfa`}
//           </p>
//           {/* <button
//                           className="item-btn"
//                           disabled={isItemAdded(item._id)}
//                           onClick={(e) => {
//                             e.preventDefault() // Prevent default link behavior
//                             addItemToCartList(item)
//                           }}
//                         >
//                           {isItemAdded(item._id) ? (
//                             <Link to="/cart">Go to Cart</Link>
//                           ) : (
//                             "Add To bag"
//                           )}
//                         </button> */}

//           <button
//             className={`${
//               findCartItem
//                 ? " mt-5 flex w-full items-center justify-center rounded-md  bg-red-700 py-2 text-[#fff] text-base uppercase leading-non  hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2"
//                 : " mt-5 flex w-full items-center justify-center rounded-md  bg-blue-700 py-2 text-[#fff] text-base uppercase leading-non  hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
//             }`}
//             onClick={() => addToCart(item, findCartItem)}
//           >
//             <ShoppingCartIcon
//               className={styles.shoppingCartIcon}
//               aria-hidden="true"
//             />
//             <div className="flex flex-col self-center uppercase">
//               <span className={styles.buttonText}>
//                 {findCartItem ? "Retirer du panier" : "Ajouter au Panier"}
//               </span>
//             </div>
//           </button>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Card

import styles from "./styles.module.css"
import { ShoppingCartIcon } from "@heroicons/react/solid"
import { useState } from "react"
import { Link } from "react-router-dom"
import InnerTag from "../Tag/InnerTag"

const Card = ({
  item,
  addToFavorite,
  findFavoriteItem,
  addToCart,
  findCartItem,
}) => {
  const [selectedCategory, setSelectedCategory] = useState("all")

  const handleCategoryClick = (category) => {
    setSelectedCategory(category)
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  const parseDescription = (desc) => {
    if (typeof desc !== "string") {
      return [] // Retourne un tableau vide si desc n'est pas une chaîne
    }
    return desc.split(" //+").map((item) => item.trim())
  }

  const formatPrice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
  }

  //parseDescription(product.description)

  return (
    <div className="w-100">
      <div className="max-w-sm overflow-hidden rounded-[8px] shadow-lg">
        <Link
          to={`/detail/${item.name.split(" ").join("-")}/${item.category
            .split(" ")
            .join("-")}/${item._id}`}
          onClick={scrollToTop}
          className="group relative block"
        >
          <img
            className="h-[219px] w-full object-cover object-center transition-transform duration-300 ease-in-out group-hover:grayscale group-hover:scale-110"
            src={item.image}
            alt={item.name}
          />
          <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100">
            {/* <span className="text-white text-lg font-bold">Ton Texte Ici</span> */}

            <span className="text-white">
              <br />
              {/* <InnerTag str={product.description} /> */}

              <ul>
                {parseDescription(item.description).map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </span>
          </div>
        </Link>
        <div className="bg-[#fff] px-6 py-4" style={{ fontFamily: "Poppins" }}>
          {/* <span className="text-base uppercase">
            <InnerTag
              color={"#2e7601"}
              truncate={true}
              str={item.description}
            />
          </span> */}
          <p className="mt-2 text-base text-lg font-bold text-[#0000CC]">
            {`Prix : ${formatPrice(item.price)} cfa`}
          </p>
          <button
            className={`${
              findCartItem
                ? " mt-5 flex w-full items-center justify-center rounded-md  bg-red-700 py-2 text-[#fff] text-base uppercase leading-non  hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2"
                : " mt-5 flex w-full items-center justify-center rounded-md  bg-blue-700 py-2 text-[#fff] text-base uppercase leading-non  hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
            }`}
            onClick={() => addToCart(item, findCartItem)}
          >
            <ShoppingCartIcon
              className={styles.shoppingCartIcon}
              aria-hidden="true"
            />
            <div className="flex flex-col self-center uppercase">
              <span className={styles.buttonText}>
                {findCartItem ? "Retirer du panier" : "Ajouter au Panier"}
              </span>
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Card
