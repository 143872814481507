import { useLocation } from "react-router-dom";

function HiddenComment() {
  const location = useLocation();
  const path = location.pathname;

  const hiddenPaths = ["/detail", "/cart"];

  return !hiddenPaths.some((hiddenPath) => path.startsWith(hiddenPath));
}

export default HiddenComment;
